import { Footer, Navbar } from "../components";
import { Button } from "react-bootstrap";

import config from "../config";
import { isMobile } from "react-device-detect";

export function DonationsPage () {
    return (
        <div>
            <Navbar selected='donate' />

            <div className="text-center" style={{ paddingBottom: '45px' }}>
                <h1 className='h1' style={{ padding: '45px', paddingBottom: '20px' }}>Donations</h1>

                <p style={{ fontSize: '20px', width: '60%', margin: 'auto', fontWeight: '600' }}>Donations are greatly appreciated and all funds go towards directly benefiting the fighters, and mainting our events.</p>

                <Button href={`${config.API}/donate`} size='lg' variant="secondary" style={{ marginTop: '20px', paddingLeft: '15px', paddingRight: '15px' }}>Donate Here</Button>
            </div>

            <Footer sticky={!isMobile} />
        </div>
    );
};