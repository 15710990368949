import { Footer, Navbar } from "../components";

export function TOSPage () {
    return (
        <div>
            <Navbar />

            <div className="container pb-4">
                <h1 className="h1 pt-5">Terms & Conditions</h1>

                <div style={{ paddingBottom: '25px' }}>
                    <h2 className="pt-5">1. Use of the Website</h2>
                    <p>You agree to use the website in accordance with these Terms and all applicable laws. Prohibited activities include but are not limited to:</p>
                    <ul>
                        <li>Using the website for unlawful purposes.</li>
                        <li>Interfering with or disrupting the website’s functionality.</li>
                        <li>Engaging in activities that harm or damage our reputation or intellectual property.</li>
                    </ul>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>2. User Accounts</h2>
                    <p>
                        To access certain features, you may need to create an account. You agree to provide accurate and current information and to maintain the confidentiality of your account credentials. You are responsible for all activities under your account.
                    </p>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>3. Intellectual Property</h2>
                    <p>
                        All content on this website, including but not limited to text, graphics, logos, and images, is the property of **American Steel Fighting League** or its licensors and is protected by copyright, trademark, and other intellectual property laws. Unauthorized use of our content is prohibited.
                    </p>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>4. Third-Party Links</h2>
                    <p>
                        Our website may contain links to third-party websites. We are not responsible for the content, policies, or practices of any third-party sites. Access these websites at your own risk.
                    </p>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>5. Limitation of Liability</h2>
                    <p>
                        To the fullest extent permitted by law, **American Steel Fighting League** shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of the website or inability to access it.
                    </p>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>6. Disclaimer of Warranties</h2>
                    <p>
                        The website is provided "as is" and "as available" without any warranties of any kind, express or implied. We do not guarantee that the website will be error-free, secure, or uninterrupted.
                    </p>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>7. Indemnification</h2>
                    <p>
                        You agree to indemnify and hold harmless **American Steel Fighting League** and its affiliates from any claims, losses, liabilities, and expenses (including legal fees) arising from your use of the website or violation of these Terms.
                    </p>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>8. Modifications to the Terms</h2>
                    <p>
                        We may update these Terms from time to time. Changes will be posted on this page with an updated effective date. Continued use of the website after changes are posted constitutes your acceptance of the new Terms.
                    </p>
                </div>
            </div>

            <Footer />
        </div>
    );
}