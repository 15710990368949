import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Navbar, Footer } from "../components";
import { isMobile } from 'react-device-detect';

const Events = [
    {
        label: 'Fit & Flex Expo',
        flyer: '/flyers/fit-and-flex.jpg',
        date: 'November 16-17, 2024',
        location: 'Ft. Lauderdale, Florida'
    },
    {
        label: "Husaria Cup",
        flyer: '/flyers/split-rock.png',
        date: 'August 10, 2024',
        location: 'Split Rock Hotel, Lake Harmony, Pennsylvania',
        time: '6:00 p.m. - 10:00 p.m.'
    }
];

export function EventsPage () {
    return (
        <div>
            <Navbar selected='events' />

            <Container>
                <div style={{ paddingTop: '30px', paddingBottom: '45px' }}>
                    <h1 className="h1">Events</h1>

                    <div style={{ padding: "25px 0 0 0" }}>
                        <h1 className="h2">2024 Events</h1>

                        <div style={{ width: "100%", backgroundColor: 'rgb(33, 37, 41)', height: "4px", borderRadius: '4px' }} />


                        <div className="row">
                            {Events.map((event, index) => (
                                <div key={index} className="col-md-4" style={{ padding: '15px', color: '#eee', marginTop: '15px', borderRadius: '6px' }}>
                                    <div style={{ backgroundColor: 'rgb(49, 49, 49)', padding: '25px' }}>
                                        <a href={event.flyer} target="_blank">
                                            <img src={event.flyer} style={{ width: '100%' }} />
                                        </a>

                                        <div style={{ padding: '25px', paddingLeft: '0', height: "200px" }}>
                                            <h1 className="h3" style={{ fontSize: "24px" }}>{event.label}</h1>

                                            <h1 className="h4" style={{ paddingTop: '20px', fontSize: "20px" }}>Date: {event.date}</h1>

                                            <h1 className="h4" style={{ paddingTop: '15px', fontSize: "20px" }}>Location: {event.location}</h1>

                                            {/* <OverlayTrigger
                                        placement="left"
                                        overlay={<Tooltip>Event closed.</Tooltip>}
                                    >
                                        <Button variant='secondary' style={{ marginTop: '5px', cursor: 'not-allowed' }}>
                                            Purchase Tickets
                                        </Button>
                                    </OverlayTrigger> */}
                                        </div>

                                        <Button style={{ width: '100%' }} size='lg' variant='secondary'>View More</Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Container >

            <Footer />
        </div >
    );
}