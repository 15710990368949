import { useEffect, useRef, useState } from "react";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import Player from "@vimeo/player";

export function Header ({ height, width }) {
    const iframeRef = useRef(null);

    const slides = 2;
    const [Slide, setSlide] = useState(0);
    const [VideoLoaded, setVideoLoaded] = useState(false);
    const [Referrer, setReferrer] = useState(generateReferrer());

    function generateReferrer () {
        return `ref-${Math.random().toString(36).substr(2, 9)}`; // Generates a unique referral ID
    }

    function slideUp () {
        if (Slide + 1 >= slides) {
            setSlide(0);
        } else {
            setSlide((i) => i + 1);
        }
    }

    function slideDown () {
        if (Slide - 1 <= -1) {
            setSlide(slides - 1);
        } else {
            setSlide((i) => i - 1);
        }
    }

    function leftClick (e) {
        e.preventDefault();
        slideDown();
    }

    function rightClick (e) {
        e.preventDefault();
        slideUp();
    }

    useEffect(() => {
        if (Slide === 0) {
            setVideoLoaded(false);
            setReferrer(generateReferrer()); // Update referral when the slide changes back to 0
        }

        const timeout = setTimeout(() => {
            setSlide((i) => i--);
        }, 5000);

        return () => clearTimeout(timeout);
    }, [Slide]);

    useEffect(() => {
        const iframe = iframeRef.current;
        const player = new Player(iframe);

        player.on("play", () => {
            setVideoLoaded(true);
            console.log(`Video started with referrer: ${Referrer}`);
        });

        player.on("loaded", () => {
            console.log(`Video reloaded with updated referrer: ${Referrer}`);
        });

        return () => {
            player.off("play");
            player.off("loaded");
        };
    }, [Referrer]);

    return (
        <>
            <style>
                {`
          .outer {
              position: absolute;
              top: 0px;
              height: 300px;
              width: 30px;
          }
          .inner {
              transform: translateX(-50%) translateY(-50%) rotate(90deg);
              margin-left: 10px;
              position: absolute;
              top: 50%;
              text-align: center;
          }
        `}
            </style>

            {Slide === 0 && (
                <div
                    style={{
                        width: isMobile ? "100%" : "60%",
                        position: "relative",
                        margin: "auto",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "8px",
                            color: "#eee",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {!VideoLoaded && (
                            <div
                                style={{
                                    width: "100%",
                                    textAlign: "center",
                                    position: "absolute",
                                }}
                            >
                                <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ fontSize: "25px", height: "125px", width: "125px" }}
                                >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>

                                <p
                                    style={{
                                        fontStyle: "italic",
                                        paddingTop: "25px",
                                        fontSize: "22px",
                                    }}
                                >
                                    Loading Video...
                                </p>
                            </div>
                        )}

                        <div
                            style={{
                                padding: "0",
                                position: "relative",
                                width: "100%",
                                height: "450px",
                            }}
                        >
                            <iframe
                                ref={iframeRef}
                                src={`https://player.vimeo.com/video/1032160844?autoplay=1&background=1&muted=1&referrer=${Referrer}`}
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                }}
                                title="ASFL Intro #1"
                            ></iframe>
                        </div>

                        <div
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                height: '100%',
                                width: '100%'
                            }}
                        >
                            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', color: '#eee' }}>
                                <a href='#' onClick={leftClick} style={{ width: '10%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#eee' }}>
                                    <div>
                                        <h1 style={{ padding: '0', margin: '0' }} className="h2">
                                            <i className="fas fa-chevron-left"></i>
                                        </h1>
                                    </div>
                                </a>

                                <div style={{ width: '80%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {/* <div className="text-center">
                                <h1 className="h5">
                                    American Steelfighting League Presents
                                </h1>

                                <h1 className="h2">
                                    Husaria Cup
                                </h1>

                                <h1 className="h5">
                                    Aug. 10 at the Split Rock Hotel
                                </h1>

                                <Button variant='secondary' href='/tickets' style={{ marginTop: '5px' }}>
                                    Purchase Tickets
                                </Button>
                            </div> */}
                                </div>

                                <a href='#' onClick={rightClick} style={{ width: '10%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#eee' }}>
                                    <div>
                                        <h1 style={{ padding: '0', margin: '0' }} className="h2">
                                            <i className="fas fa-chevron-right"></i>
                                        </h1>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div
                            style={{
                                position: 'absolute',
                                bottom: '20px',
                                right: '20px',
                                height: '35px',
                                width: '50px',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >

                            <div style={{ textAlign: 'center', marginTop: 'auto' }}>
                                <a
                                    href='#'
                                    // onClick={() => setVideoMuted(b => !b)}
                                    style={{
                                        color: "#eee",
                                        margin: "0",
                                        padding: '0',
                                        fontSize: '25px'
                                    }}
                                >
                                    <h1 style={{ padding: '0', margin: '0' }} className="h2">
                                        {/* <i className={!VideoMuted ? "fas fa-volume-up" : 'fas fa-volume-mute'}></i> */}
                                    </h1>
                                </a>
                            </div>
                        </div >
                    </div>
                </div>
            )}

            {Slide == 1 && (
                <div
                    style={{
                        height: isMobile ? '250px' : '200px',
                        width: '100%',
                        position: 'relative'
                    }}
                >
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            borderRadius: "8px",
                            backgroundColor: 'black',
                            color: '#eee',
                            display: 'flex',
                            backgroundImage: "url(\"/pictures/15.jpg\")",
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '50% 20%',
                        }}
                    />

                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            borderRadius: "8px",
                            backgroundColor: 'rgba(0, 0, 0, 0.35)',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                        }}
                    />

                    <div
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            height: '100%',
                            width: '100%'
                        }}
                    >
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', color: '#eee' }}>
                            <a href='#' onClick={leftClick} style={{ width: '10%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#eee' }}>
                                <div>
                                    <h1 style={{ padding: '0', margin: '0' }} className="h2">
                                        <i className="fas fa-chevron-left"></i>
                                    </h1>
                                </div>
                            </a>

                            <div style={{ width: '80%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className="text-center">
                                    <h1 className="h5">
                                        American Steelfighting League Presents
                                    </h1>

                                    <h1 className="h2">
                                        Husaria Cup
                                    </h1>

                                    <h1 className="h5">
                                        Aug. 10 at the Split Rock Hotel
                                    </h1>

                                    <OverlayTrigger
                                        placement="left"
                                        overlay={<Tooltip>Event closed.</Tooltip>}
                                    >
                                        <Button variant='secondary' style={{ marginTop: '5px', cursor: 'not-allowed' }}>
                                            Purchase Tickets
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </div>

                            <a href='#' onClick={rightClick} style={{ width: '10%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#eee' }}>
                                <div>
                                    <h1 style={{ padding: '0', margin: '0' }} className="h2">
                                        <i className="fas fa-chevron-right"></i>
                                    </h1>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
