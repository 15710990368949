import { Navbar, Container } from "react-bootstrap";
import { isMobile } from 'react-device-detect';

import Config from '../config';

export function Footer ({ sticky }) {
    const footerWidth = '70%';

    const linkSize = '28px';

    return (
        <Navbar fixed={sticky ? "bottom" : null} style={{ padding: '0' }}>
            <div style={{ padding: '0', margin: '0', width: "100%" }}>
                <div
                    style={{
                        // height: "100px",
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'rgb(49, 49, 49)',
                        color: 'rgb(230, 2#0, 230)'
                    }}
                >
                    <div
                        className="container"
                        style={{
                            margin: 'auto',
                            paddingTop: '28px',
                            paddingBottom: '28px',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <a href='/events' style={{ marginRight: '25px', textDecoration: 'underline', color: 'rgb(255, 255, 255)' }}>
                            <h1 style={{ fontSize: "22px" }}>Events</h1>
                        </a>

                        <a href='/store' style={{ marginRight: '25px', textDecoration: 'underline', color: 'rgb(255, 255, 255)' }}>
                            <h1 style={{ fontSize: "22px" }}>Store</h1>
                        </a>

                        {/* <a href='/tickets' style={{ marginRight: '25px', textDecoration: 'underline', color: 'rgb(255, 255, 255)' }}>
                            <h1 style={{ fontSize: "22px" }}>Tickets</h1>
                        </a>
                        <a href='/sponsors' style={{ textDecoration: 'underline', color: 'rgb(255, 255, 255)' }}>
                            <h1 style={{ fontSize: "22px" }}>Sponsors</h1>
                        </a> */}
                    </div>
                </div>

                <div
                    style={{
                        backgroundColor: 'rgb(36, 36, 36)',
                        color: 'rgb(160, 160, 160)'
                    }}
                >
                    <div
                        className="container"
                        style={{
                            margin: 'auto',
                            display: !isMobile ? 'flex' : null,
                            alignItems: 'center',
                            height: "100%",
                            paddingTop: '28px',
                            paddingBottom: '28px'
                        }}
                    >
                        <div style={{ fontSize: '!8px' }}>
                            <p style={{ margin: '0', padding: '0', fontWeight: '600' }}>
                                &copy; 2024 American Steelfighting League - All rights reserved
                            </p>

                            <p style={{ margin: '0', padding: '0', fontWeight: '600', paddingTop: '8px' }}>
                                Designed By{' '}
                                <a
                                    target="_blank"
                                    href='https://www.breakingpoint.software'
                                    style={{
                                        color: 'rgb(160, 160, 160)'
                                    }}
                                >
                                    Breaking Point Software
                                </a>
                            </p>

                            <p style={{ margin: '0', padding: '0', fontWeight: '600', paddingTop: '8px' }}>
                                <a href='/privacy-policy' style={{
                                    color: 'rgb(160, 160, 160)'
                                }}>Privacy Policy</a> | <a href='/tos' style={{
                                    color: 'rgb(160, 160, 160)'
                                }}>Terms & Conditions</a>
                            </p>
                        </div>

                        <div style={{ fontSize: '18px', marginLeft: 'auto', display: 'flex', paddingTop: isMobile ? '15px' : null }}>
                            <a href={Config.instagram} target="_blank" style={{ marginRight: '20px' }}>
                                <h1 style={{ fontSize: linkSize, margin: '0', padding: '0', color: 'rgb(160, 160, 160)' }}>
                                    <i className="fab fa-instagram"></i>
                                </h1>
                            </a>
                            <a href={Config.tiktok} target="_blank" style={{ marginRight: '20px' }}>
                                <h1 style={{ fontSize: linkSize, margin: '0', padding: '0', color: 'rgb(160, 160, 160)' }}>
                                    <i className="fab fa-tiktok"></i>
                                </h1>
                            </a>
                            <a href={Config.facebook} target="_blank">
                                <h1 style={{ fontSize: linkSize, margin: '0', padding: '0', color: 'rgb(160, 160, 160)' }}>
                                    <i className="fab fa-facebook"></i>
                                </h1>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Navbar >
    );
}