import {
	SearchPage,
	EventsPage,
	GalleryPage,
	SponsorsPage,
	TicketsPage,
	LandingPage,
	NotFoundPage,
	DonationsPage,
	PrintTicketsPage,
	ContactPage,
	TOSPage,
	PrivacyPolicy,
} from './index'

export * from './index'

export const Routes = [
	{
		path: '/',
		label: 'Home',
		element: <LandingPage />,
		errorElement: <NotFoundPage />,
		id: 'home',
		// hidden: true,
	},
	{
		path: '/events',
		label: 'Events',
		element: <EventsPage />,
		id: 'events',
	},
	// {
	// 	path: '/tickets',
	// 	label: 'Purchase Tickets',
	// 	element: <TicketsPage />,
	// 	rightAligned: true,
	// },
	// {
	// 	path: '/search',
	// 	icon: <i className='fas fa-search'></i>,
	// 	element: <SearchPage />,
	// 	rightAligned: true,
	// },
	{
		path: '/gallery',
		label: 'Gallery',
		element: <GalleryPage />,
		id: 'gallery',
	},
	// {
	// 	path: '/fighters',
	// 	element: <FightersListPage />,
	// },
	// {
	// 	path: '/rules',
	// 	element: <RulesPage />,
	// },
	// {
	// 	path: '/sponsors',
	// 	label: 'Sponsors',
	// 	element: <SponsorsPage />,
	// },
	{
		path: '/donate',
		label: 'Donate',
		element: <DonationsPage />,
		id: 'donate',
	},
	{
		path: '/contact',
		label: 'Contact',
		element: <ContactPage />,
		id: 'contact',
	},
	{
		path: '/tickets/print',
		label: 'Print Tickets',
		element: <PrintTicketsPage />,
		hidden: true,
	},
	{
		path: '/tos',
		label: 'TOS',
		element: <TOSPage />,
		hidden: true,
	},
	{
		path: '/privacy-policy',
		label: 'Privacy Policy',
		element: <PrivacyPolicy />,
		hidden: true,
	},
]
