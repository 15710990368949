import { Footer, Navbar } from "../components";

export function PrivacyPolicy () {
    return (
        <div>
            <Navbar />

            <div className="container pb-4">
                <h1 className="h1 pt-5">Privacy Policy</h1>

                <div style={{ paddingBottom: '25px' }}>
                    <h2 className="pt-5">1. Information We Collect</h2>
                    <ul>
                        <li><strong>Personal Information:</strong> Name, email address, phone number, mailing address, and other details you provide when filling out forms or signing up for services.</li>
                        <li><strong>Non-Personal Information:</strong> Browser type, device type, IP address, pages visited, and other analytics data collected through cookies and similar technologies.</li>
                        <li><strong>Payment Information:</strong> If you make a purchase or donation, we may collect payment details, such as credit card information, through secure third-party processors.</li>
                    </ul>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>2. How We Use Your Information</h2>
                    <ul>
                        <li>To provide and manage our services.</li>
                        <li>To process transactions securely.</li>
                        <li>To communicate updates, promotions, and relevant information.</li>
                        <li>To improve our website, services, and user experience.</li>
                        <li>To comply with legal obligations.</li>
                    </ul>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>3. Sharing Your Information</h2>
                    <p>We do not sell or rent your personal information. We may share your information with:</p>
                    <ul>
                        <li><strong>Service Providers:</strong> Third-party vendors that assist in website operations, payment processing, or email delivery.</li>
                        <li><strong>Legal Compliance:</strong> When required by law or in response to valid legal requests.</li>
                        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner.</li>
                    </ul>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>4. Cookies and Tracking Technologies</h2>
                    <p>
                        Our website uses cookies and similar technologies to enhance your browsing experience. These technologies help us:
                    </p>
                    <ul>
                        <li>Remember your preferences.</li>
                        <li>Analyze website traffic and usage patterns.</li>
                    </ul>
                    <p>
                        You can manage cookie settings in your browser, but disabling cookies may affect certain website functionalities.
                    </p>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>5. Security</h2>
                    <p>
                        We implement industry-standard security measures to protect your information. However, no system is completely secure, and we cannot guarantee the absolute safety of your data.
                    </p>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>6. Your Rights</h2>
                    <p>Depending on your location, you may have the following rights:</p>
                    <ul>
                        <li>Access, update, or delete your personal information.</li>
                        <li>Opt-out of receiving marketing emails.</li>
                        <li>Request a copy of the data we hold about you.</li>
                    </ul>
                    <p>To exercise these rights, contact us at <strong>support@breakingpoint.software</strong>.</p>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>7. Third-Party Links</h2>
                    <p>
                        Our website may contain links to third-party websites. We are not responsible for their privacy practices, and we encourage you to review their policies.
                    </p>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>8. Children's Privacy</h2>
                    <p>
                        Our website is not intended for individuals under the age of 13. We do not knowingly collect personal information from children.
                    </p>
                </div>

                <div style={{ paddingBottom: '25px' }}>
                    <h2>9. Changes to This Policy</h2>
                    <p>
                        We may update this Privacy Policy from time to time. Changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically.
                    </p>
                </div>
            </div>

            <Footer />
        </div>
    );
}