import { SitePasswordPage } from './pages'

import { Routes, Store } from './pages/routes'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

const router = createBrowserRouter(Routes)

function App() {
	return process.env.REACT_APP_LOCKED ? (
		<div>
			<SitePasswordPage>
				<RouterProvider router={router} />
			</SitePasswordPage>
		</div>
	) : process.env.REACT_APP_STORE ? (
		<div>
			<Store />
		</div>
	) : (
		<div>
			<RouterProvider router={router} />
		</div>
	)
}

export default App
