import { Footer, Navbar } from '../components';
import { isMobile } from 'react-device-detect';

export function GalleryPage () {
    const galleryItems = ['15.jpg', '12.jpg', '11.jpg', '13.jpg', '14.jpg', '10.jpg', '8.jpg', '4.jpg', '5.jpg', '2.jpg', '7.jpg', '1.jpg', '3.jpg'];

    const rows = Math.round(galleryItems.length / 4);

    const columns = 4;

    return (
        <div>
            <Navbar selected='gallery' />

            <div className="container text-center" style={{ paddingBottom: '35px' }}>
                <h1 className='h1' style={{ padding: '45px' }}>Gallery</h1>

                {isMobile ? (
                    <div>
                        {galleryItems.map((item) => {
                            return (
                                <div>
                                    <img src={`/pictures/${item}`} style={{ width: '100%', marginBottom: '25px' }} />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div style={{ display: 'flex' }}>
                        {[...Array(columns)].map((x, i) =>
                            <div style={{ width: '100%' }}>
                                {[...Array(columns)].map((x, z) =>
                                    <div style={{ width: `calc(100%)`, padding: '15px' }}>
                                        {
                                            galleryItems[i + (z * 3) + z] &&
                                            <a href={`/pictures/${galleryItems[i + (z * 3) + z]}`} target="_blank">
                                                <img
                                                    src={`/pictures/${galleryItems[i + (z * 3) + z]}`}
                                                    style={{
                                                        width: `100%`
                                                    }}
                                                />
                                            </a>
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>

            <Footer />
        </div>
    );
}