import { Navbar, Footer } from '../components';

export function Store () {

    return (
        <div>
            <Navbar />

            {/* <div className="container text-center" style={{ paddingTop: '65px', paddingBottom: '65px' }}>
                <h1 className='h1'>Offical ASFL Merchandise</h1>

                <div className="row">
                    <div className="col-md-4">1</div>
                    <div className="col-md-4">2</div>
                    <div className="col-md-4">3</div>
                </div>
            </div> */}

            <h1 style={{ fontStyle: 'italic', textAlign: 'center', padding: '165px' }}>Coming Soon!</h1>

            <Footer />
        </div >
    );
}